import { ActionProps } from '~/components/Actions/props';
import IconCog from '~/icons/Cog';
import IconTruck from '~/icons/Truck';
import IconUserTie from '~/icons/UserTie';

const useParams = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string): string | undefined =>
    pathname.slice(0, `/espaces/${espaceId}/params/${to}`.length) ===
    `/espaces/${espaceId}/params/${to}`
      ? 'is-active'
      : undefined;

  return [
    {
      className: isActive(`features`),
      icon: IconCog,
      label: 'Caractéristiques',
      to: `/espaces/${espaceId}/params/features`,
    },
    {
      className: isActive(`professionals`),
      icon: IconUserTie,
      label: 'Professionnels',
      to: `/espaces/${espaceId}/params/professionals`,
    },
    {
      className: isActive(`livraisons`),
      icon: IconTruck,
      label: 'Livraisons',
      to: `/espaces/${espaceId}/params/livraisons`,
    },
  ];
};

export default useParams;
